<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-start pa-5>
              <v-flex xs12>
                <span class="heading">Snake Bite Details</span>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-card flat>
                      <v-layout wrap justify-center pa-2>
                        <v-flex xs12 sm12 lg12>
                          <v-layout wrap justify-start>
                            <v-flex xs12 sm6 lg4 md6 pt-2>
                              <v-flex xs12 text-left>
                                <span class="itemKey">Name</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.name || '-' }}
                                </span>
                              </v-flex>
                            </v-flex>
                            <v-flex xs12 sm6 lg4 md6 pt-2>
                                <v-flex xs12 text-left>
                                  <span class="itemKey">Gender</span>
                                </v-flex>
                                <v-flex xs12 text-left>
                                  <span class="itemValue">
                                    {{ data.sex || '-' }}
                                  </span>
                                </v-flex>
                              </v-flex>

                            <v-flex xs12 sm6 lg4 md6 pt-2>
                              <v-flex xs12 text-left>
                                <span class="itemKey">Phone</span>
                              </v-flex>
                              <v-flex xs12 text-left>
                                <span class="itemValue">
                                  {{ data.phone || '-' }}
                                </span>
                              </v-flex>
                            </v-flex>
                          </v-layout>

                          <!-- <v-layout wrap justify-start pt-10>
                            <v-flex xs12>
                              <span class="subheading">Photographs</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-start>
                                <v-flex xs12 lg3 sm4 md4 pa-1 v-viewer="{ movable: false }">
                                  <v-img
                                    v-if="data.photo"
                                    contain
                                    height="200px"
                                    width="300px"
                                    :src="mediaURL + data.photo"
                                
                                    class="clickable"
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout> -->


                          <v-layout wrap justify-start pt-10 v-if="data.photo">
                            <v-flex xs12>
                              <span class="subheading">Photographs</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-start>
                                <v-flex 
                                  xs12
                               
                                 
                                  sm4
                                  md3
                                  lg2
                                  v-viewer="{ movable: false }"
                                  pa-1
                                >
                                  <img  
                                    contain
                                    height="200px"
                                    width="100%"
                                  :src="mediaURL + data.photo"
                                    fill-height
                                    class="clickable"
                                  />
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>

                          <v-layout wrap justify-start pt-10>
                            <v-flex xs12>
                              <span class="subheading">Location</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex xs12>
                                  <div id="map"></div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM&libraries=visualization,drawing"></script>
<script>
var marker;
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      data: {},
      map: "",
      location: []
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    initMap() {
      var vm = this;
      var mapCanvas = document.getElementById("map");

      var mapOptions = {
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.TERRAIN,
      };

      vm.map = new google.maps.Map(mapCanvas, mapOptions);

      if (vm.location && vm.location.length === 2) {
        var lat = vm.location[1];
        var lon = vm.location[0];

        mapOptions.center = new google.maps.LatLng(lat, lon);
        vm.map.setOptions(mapOptions);

        var marker = new google.maps.Marker({
          map: vm.map,
          position: new google.maps.LatLng(lat, lon),
        });

        // Create a geocoder instance
        var geocoder = new google.maps.Geocoder();
        
        var infowindow = new google.maps.InfoWindow({
          maxWidth: 300 // Set max width for info window
        });

        marker.addListener("click", function () {
          // Get address from coordinates when marker is clicked
          geocoder.geocode({
            location: {lat: lat, lng: lon}
          }, function(results, status) {
            if (status === 'OK') {
              if (results[0]) {
                // Format the address nicely
                var formattedAddress = results[0].formatted_address;
                infowindow.setContent(
                  '<div style="padding: 8px;">' +
                  '<h4 style="margin: 0 0 8px 0;">Reported Location</h4>' +
                  '<p style="margin: 0;">' + formattedAddress + '</p>' +
                  '</div>'
                );
                infowindow.open(vm.map, marker);
              }
            }
          });
        });
      } else {
        mapOptions.center = new google.maps.LatLng(11.5623, 76.5345);
        vm.map.setOptions(mapOptions);
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/snakebite/all",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            // Find the specific record matching the query ID
            const selectedData = response.data.data.find(
              item => item._id === this.$route.query.id
            );
            
            if (selectedData) {
              this.data = selectedData;
              this.location = selectedData.location;
              this.appLoading = false;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.initMap();
            } else {
              this.msg = "Record not found";
              this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
.itemKey {
  font-family: poppinsregular;
  font-size: 14px;
  color: #666;
}
.itemValue {
  font-family: poppinsregular;
  font-size: 14px;
}
#map {
  height: 300px !important;
  width: 100%;
}
</style>